<template>
    <div class="wheeler-note-100">
        <div class="wheel-header">
            <slot></slot>
        </div>
        <div class="wheelers">
        <div class="wheeler tens" @wheel="onWheel" @touchstart="onTouchStart" @touchmove="onTouchMove" @touchend="onTouchEnd">{{ ten }}</div>
        <div class="wheeler unit" @wheel="onWheelUnit" @touchstart="onTouchStartUnit" @touchmove="onTouchMoveUnit" @touchend="onTouchEndUnit">{{ (ten != 10) ? unit : 0 }}</div>
        </div> 
        <div class="wheel-footer">
            <slot name="footer"></slot>
        </div>

    </div>
</template>

<script>

export default{
    model:{
        prop: "value",
        event: "change",
    },
    props:{
        value: { default: 0},
    },
    data(){
        return {
            ten: Math.floor(this.value / 10),
            unit: this.value % 10,
            yTouchStart: 0,
            yTouchUnitStart:0,
            yTouch: 0,
            yTouchUnit: 0
        }
    },
    methods:{
        onWheel: function(e){
            e.preventDefault();
            var val = this.ten -= Math.sign(e.deltaY);
            if(val > 10) val = 10; //0;
            if(val < 0) val = 0;//10;
            this.ten = val;
            this.$emit("change", (this.ten * 10) + (this.ten != 10 ? this.unit : 0));
        },
        onWheelUnit: function(e){
            e.preventDefault();
            if(this.ten == 10) return;
            var val = this.unit -= Math.sign(e.deltaY);
            if(val > 9) val = 0;
            if(val < 0) val = 9;
            this.unit = val;
            this.$emit("change", (this.ten * 10) + (this.ten != 10 ? this.unit : 0));
        },
        onTouchStart: function(e){
            e.preventDefault();
            this.yTouchStart = e.touches[0].pageY
        },
        onTouchStartUnit: function(e){
            e.preventDefault();
            this.yTouchUnitStart = e.touches[0].pageY
        },
        onTouchMove: function(e){
            e.preventDefault();
            this.yTouch = e.touches[0].pageY
        },
        onTouchMoveUnit: function(e){
            e.preventDefault();
            this.yTouchUnit = e.touches[0].pageY
        },
        onTouchEnd: function(e){
            e.preventDefault();
            var val = this.ten -= Math.sign(this.yTouch - this.yTouchStart);
            if(val > 10) val = 10; //0;
            if(val < 0) val = 0; //10;
            this.ten = val;
            this.$emit("change", (this.ten * 10) + (this.ten != 10 ? this.unit : 0));
        },
        onTouchEndUnit: function(e){
            e.preventDefault();
            var val = this.unit -= Math.sign(this.yTouchUnit -  this.yTouchUnitStart);
            if(val > 9) val = 0;
            if(val < 0) val = 9;
            this.unit = val;
            this.$emit("change", (this.ten * 10) + (this.ten != 10 ? this.unit : 0));
        }
    }
}
</script>

<style scoped>
    div.wheeler-note-100{
        display:flex;
        flex-direction: column;
        max-width: 175px;
    }
    div.wheeler-note-100 div.wheel-header, 
    div.wheeler-note-100 div.wheel-footer{
        display:flex;
        align-items: center;
        justify-content: center;
    }

    div.wheeler-note-100 div.wheelers{
        width: 175px; height:100;
        display: flex;
        border: solid 1px black;
        display: flex;
    }
    div.wheeler-note-100 div.wheeler{
        color:red;
        font-size:80px;
        width:75px !important;
        height: 100px !important;
        border:solid 1px grey;
        background: linear-gradient(lightgrey, transparent, transparent, transparent, transparent, lightgrey);
        margin-left:1px;
        margin-right:1px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
    }
   div.wheeler-note-100 div.wheeler.tens{
        justify-content: flex-end;
        width: 100px !important;
        padding-right:3px;
    }
    div.wheeler-note-100 div.wheeler.unit{
        justify-content: flex-start;
        padding-left: 3px;
    }
</style>